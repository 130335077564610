import {AfterViewInit, Component, DestroyRef, ElementRef, inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {AuthService} from '../core/services';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from 'primeng/api';
import {RegistrationService} from '../core/services/registration.service';
import {SwiperContainer} from 'swiper/element';
import SwiperOptions from 'swiper';
import {User} from '../core/models/user';
import {Store} from '@ngxs/store';
import {SetCurrentUser} from '../shared/state/user.actions';
import _default from 'chart.js/dist/plugins/plugin.tooltip';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {CookieService} from 'ngx-cookie-service';
import {StatisticService} from "../core/services/statistic.service";
import {BreakpointObserver} from "@angular/cdk/layout";
import {distinctUntilChanged} from "rxjs/operators";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit, AfterViewInit {
    @ViewChild('loginForm') loginForm: { invalid: any };
    @ViewChild('resetForm') resetForm: { invalid: any };
    @ViewChild('backgroundSwiper') backgroundSwiper!: ElementRef<SwiperContainer>;
    forgotPasswordDialog = false;
    msgs: any;
    formData = {
        username: '',
        password: '',
    };
    error: string;
    loading = false;
    email: string;
    resetSubmitted = false;
    public passwortReset: boolean;
    swiperBackgroundSlider: SwiperOptions = {
        slidesPerView: 1,
        loop: true,
        effect: 'fade',
        fadeEffect: {
            crossFade: true,
        },
        autoplay: {
            // @ts-ignore
            delay: 4000,
        },
        speed: 4000,
    };
    @ViewChild('overlayMessage') private overlayMessageElement: ElementRef;
    private destroyRef = inject(DestroyRef);

    source: string = 'desktop';
    readonly breakpoint$ = this.breakpointObserver.observe(['(min-width: 1025px)']).pipe(distinctUntilChanged());

    constructor(
        private authService: AuthService,
        private router: Router,
        private route: ActivatedRoute,
        private message: MessageService,
        private registrationService: RegistrationService,
        private store: Store,
        private statisticService: StatisticService,
        private breakpointObserver: BreakpointObserver,
        private cookieService: CookieService) {
    }

    ngOnInit(): void {

    }

    login(): void {
        if (this.loginForm.invalid) {
            this.showError($localize`:@@error.incomplete:Eingaben unvollständig`);
        } else {
            const data = this.authService
                .login(this.formData.username, this.formData.password)
                .then((data) => {
                    localStorage.setItem('currentUser', JSON.stringify(data));
                    this.authService.userSubject.next(data as User);
                    this.authService
                        .getUserProfile()
                        .pipe(takeUntilDestroyed(this.destroyRef))
                        .subscribe((r) => {
                            this.store.dispatch(new SetCurrentUser(r));
                            localStorage.setItem('currentUserData', JSON.stringify(r));
                            const insert = { buyer_id: r.id, hotel_id: 0, visit_duration: null, type: 'login', source: this.source };
                            this.statisticService.createStatistic(insert).subscribe();


                            this.router.navigate(['/dashboard']);
                        });
                })
                .catch((error) => {
                    const hint = error.body.hint;
                    if (hint !== undefined && hint === 'loopplus_error') {
                        this.showError($localize`:@@error.login.loopplus:Dein Zugang ist noch nicht für Loop Plus freigeschaltet. Sende uns eine kurze Mail an loopplus@lobster-event.com mit der Bitte um Aktivierung.`, 30000);
                    } else {
                        this.showError($localize`:@@error.login.failed:Login fehlgeschlagen!`);
                    }
                });
        }
    }

    showError(msg: string, duration: number = 5000): void {
        this.message.add({
            key: 'msgs',
            severity: 'error',
            summary: $localize`:@@show.error:Fehler`,
            life: duration,
            detail: msg,
        });
    }

    showMessage(msg: string, type = 'error', summary = 'Fehler'): void {
        this.message.add({
            key: 'msgs',
            severity: type,
            life: 5000,
            summary,
            detail: msg,
        });
    }

    public showPasswortReset(show: boolean) {
        this.passwortReset = show;
    }

    hideOverlayMessage(): void {
        this.overlayMessageElement.nativeElement.remove();
        this.cookieService.set('credentials', 'true', 365);
    }

    resetPasswordData(): void {
        this.resetSubmitted = true;
        if (this.resetForm.invalid) {
            this.showError($localize`:@@password.reset.error:Bitte geben Sie Ihre Email-Adresse ein!`);

            return;
        }
        this.registrationService
            .passwordforgot(this.email)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (d) => {
                    this.forgotPasswordDialog = false;
                    this.email = '';
                    this.showMessage(
                        $localize`:@@password.reset.message:Wir haben Ihre Anfrage zum Zurücksetzen Ihres Passworts erhalten. Bitte prüfen Sie Ihr Email-Postfach. Sie werden in Kürze eine Email von uns mit Anweisungen zum zurücksetzen Ihres Passworts erhalten.`,
                        'success',
                        $localize`:@@successful:Erfolgreich!`
                    );
                },
                error: () => {
                    this.forgotPasswordDialog = false;
                    this.email = '';
                    this.showMessage($localize`:@@unknown.email.address:Email-Adresse nicht bekannt!`, 'error', $localize`:@@error:Fehler!`);
                },
            });
    }

    private breakpointChanged() {
        if (this.breakpointObserver.isMatched('(min-width: 1025px)')) {
            this.source = 'desktop';
        } else if (this.breakpointObserver.isMatched('(max-width: 1024px)')) {
            this.source = 'tablet';
        } else if (this.breakpointObserver.isMatched('(max-width: 480px)')) {
            this.source = 'phone';
        }
    }

    ngAfterViewInit(): void {
        if (this.cookieService.get('credentials')) {
            this.overlayMessageElement.nativeElement.remove();
        }
        this.backgroundSwiper.nativeElement.initialize();
    }
}
